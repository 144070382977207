import { gql, useQuery } from '@apollo/client';
import { Table } from 'react-bootstrap';
import { Photo } from './models/Photo';
import PhotoComponent from './PhotoComponent';

interface PhotosContainer {
  photos: Photo[]
}

const GET_PHOTOS = gql`
  query Photo {
    photos {
      id
      name
      location
      caption
      date
      country
    }
  }
`;

function Photos() {

  const { loading, data } = useQuery<PhotosContainer>(
    GET_PHOTOS
  );
    
  return (
    <div>
      { loading ? 
        <p></p>
        :
        <div className="container">
        <Table bordered>
          <tr>
          <th>ID</th>
          <th>Date</th>
          <th>Location</th>
          <th>Country</th>
          <th>Caption</th>
          <th>Image</th>
          </tr>
        { data && data.photos.map(photo =>
        <tr><td>{photo.id}</td><td>{photo.date}</td><td>{photo.location}</td><td>{photo.country}</td><td>{photo.caption}</td><td>
          <PhotoComponent 
            photoId={photo.id}
            photoLocation={photo.location}
            photoCaption={photo.caption}
            photoDate={photo.date}
            photoName={photo.name}
          /></td></tr>
        )
        
        }
        </Table>
        </div>
      }
    </div>
  );
};

export default Photos;
