import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { SiteConfig } from './SiteConfig';

function PhotoComponent(props: {photoId?: number, photoName?: string, photoCaption?: string, photoLocation?: string, photoDate?: string, photoCountry?: string})  {

  const name = props.photoName
  const date = props.photoDate
  const caption = props.photoCaption
  const location = props.photoLocation
  const country = props.photoCountry
  const [isOpen, setIsOpen] = useState(false)

  const handleShow = () => {
      setIsOpen(!isOpen);
    };
  
  const handleClose = () => {
    setIsOpen(false)
  }
  
    return (
        <span>
          <a href="javascript:void(0);"><img
            className="small"
            width="35" height="20"
            src={`${SiteConfig.BASE_URL}/img/photo-camera.svg`}
            onClick={handleShow}
            alt="no image"
          /></a>
      <Modal show={isOpen} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{date}.  {location}, {country}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{caption}</div>
          <img width="100%" height="auto" src={`${SiteConfig.BASE_URL}/img/${name}`}/>
          </Modal.Body>
       </Modal>        
      </span>
      );
    }

  export default PhotoComponent;