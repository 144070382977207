import { gql, useQuery } from '@apollo/client';
import { Stack } from 'react-bootstrap';
import Trip from './models/Trip';
import { SiteConfig } from './SiteConfig';


interface TripContainer {
    findTrip: Trip;
    }
    

const GET_TRIP = gql`
    query Trip {
    findTrip(id: 1) {
        aboutText,
        aboutImage,
        aboutImageCaption
    }
}`;

function About(){

      
    const { loading, data } = useQuery<TripContainer>(
        GET_TRIP
      );
    
    return <div className="container">
        <div style={{maxWidth:"600px"}}>{data?.findTrip.aboutText}</div>
        <Stack>
            <div style={{textAlign: "center", width:"600px"}}><b>{data?.findTrip.aboutImageCaption}</b></div>
        <img style={{width: "600px", height:"auto"}} src={`${SiteConfig.BASE_URL}/img/${data?.findTrip.aboutImage}`}/>
        </Stack>
      </div>
}

export default About;