import { gql, useQuery } from '@apollo/client';
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { Stack } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Map from './Map';
import Marker from './Marker';
import Trip from './models/Trip';
import { SiteConfig } from './SiteConfig';

interface TripContainer {
    findTrip: Trip;
  }
  

const GET_TRIP = gql`
  query Trip {
    findTrip(id: 1) {
      rides{
          startCoordinates {
              lat,
              lng
          }

      },
      lastJournalEntry{
          id,
          startLocation,
          endDate,
          endLocation,
          image,
          imageCaption,
          summary
      }
    }
  }
`;

function Home() {
    const { loading, data } = useQuery<TripContainer>(
        GET_TRIP
      );
    
    const render = (status: Status) => {
        return <h1>{status}</h1>;
      };

    return (
    <div style={{ display: "flex", height: "100%" }}>
    { loading ? 
        <div></div>
        :
      <div className='container'>
        <div>
          <Stack>
            <div><b>{data?.findTrip.lastJournalEntry.endDate}. {data?.findTrip.lastJournalEntry.endLocation}</b></div>
            <div>{data?.findTrip.lastJournalEntry.summary}</div>
            <Link
              style={{ display: "block", margin: "1rem 0" }}
              to={`/journal/${data?.findTrip.lastJournalEntry.id}`}
              key={data?.findTrip.lastJournalEntry.id}
            >
              Read More in the Last Journal Entry
            </Link>
            <div style={{textAlign: "center"}}><b>{data?.findTrip.lastJournalEntry.imageCaption}</b></div>
            <img style={{maxWidth: "100%", height:"auto"}} src={`${SiteConfig.BASE_URL}/img/${data?.findTrip.lastJournalEntry.image}`}/>
          </Stack>
        </div>
        <div>
          <Stack>
            <div style={{textAlign: "center"}}><b>Route</b></div>
            <Wrapper apiKey={SiteConfig.GOOGLE_MAPS_API_KEY} render={render}>
              <Map
                mapTypeId={'terrain'}
                center={{lat: 0, lng: -110}}
                zoom={2}
                style={{ width: "400px", height: "800px" }}
              >
                {data?.findTrip.rides.map(ride =>
                  <Marker
                    icon={{path:"M 0 0 L 4 0 L 4 4 L 0 4 z",
                    fillColor: "#656398",
                    fillOpacity: 1,
                    strokeColor: '#656398',
                    strokeWeight: 1,
                    scale: 1,}}
                    position={ride.startCoordinates}
                  />
                 )
                }
              </Map>
            </Wrapper>
          </Stack>
        </div>
      </div>
    }
    </div>
  );
}
export default Home;