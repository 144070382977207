import { gql, useQuery } from '@apollo/client';
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { Link, useParams } from 'react-router-dom';
import Map from './Map';
import { JournalEntry } from './models/JournalEntry';
import PhotoComponent from './PhotoComponent';
import Polyline from './Polyline';
import {useEffect} from 'react'
import {SiteConfig} from './SiteConfig'
import Marker from './Marker';

declare global {
  interface Window { cachedPhotos: any; }
}

interface JournalEntryContainer {
  findJournalEntry: JournalEntry
}

const GET_JOURNAL_ENTRY = gql`
  query Journal($id: Int!)  {
    findJournalEntry(id: $id) {
      id
      startLocation
      startDate
      endLocation
      endDate,
      mapCenter{
        lat,
        lng
      },
      mapZoomLevel,
      text,
      nextJournalEntryId,
      previousJournalEntryId,
      rides{
        paths{
          pathType, 
          coordinates{
            lat,
            lng
          }
        }
      },
      photos{
        id,
        name,
        location,
        country,
        caption,
        date
      }
    }
  }
`;

function JournalEntryComponent() {

  const id = useParams().journalId
  
  const { loading, data } = useQuery<JournalEntryContainer>(
    GET_JOURNAL_ENTRY,{
      variables: { id },
    }
  );
  

  const render = (status: Status) => {
    return <h1>{status}</h1>;
  };

  useEffect( () => {
    if (!loading) {
      const cacheImages = async() => {
        window.cachedPhotos = data?.findJournalEntry.photos.map(photo => {
         const img = new Image().src = SiteConfig.BASE_URL + "/img/" + photo.name;  
         return img;
        })
    }
    cacheImages()
    }
  },[loading])

  const regex = /\[photo:(\d+)\]/g;
  const isnum = /^\d+$/;
  
return (
    <div style={{ display: "flex", height: "100%" }}>
      { loading ? 
        <div></div>
        :
        <div className='container'>
          <div>
          {data?.findJournalEntry.previousJournalEntryId !== undefined && data?.findJournalEntry.previousJournalEntryId > 0 ? (
          <Link
            to={`/journal/${data?.findJournalEntry.previousJournalEntryId}`}
            key={data?.findJournalEntry.previousJournalEntryId}
            style={{marginRight:"1rem"}}
          >
            Previous
          </Link>):<></>}
            <span>
            <b>{data?.findJournalEntry.startDate} {data?.findJournalEntry.startLocation} to {data?.findJournalEntry.endDate} {data?.findJournalEntry.endLocation}</b>
            </span>
          {data?.findJournalEntry.nextJournalEntryId !== undefined && data?.findJournalEntry.nextJournalEntryId > 0 ? (
            <Link 
            to={`/journal/${data?.findJournalEntry.nextJournalEntryId}`}
            key={data?.findJournalEntry.nextJournalEntryId}
            style={{marginLeft:"1rem"}}
          >
            Next
          </Link>):<></>}
          </div>
           
          <div>
            {data?.findJournalEntry.text.split('<br><br>').map(text => {
              return[
            text.split(regex).map(t => t.match(isnum) ? 
              <PhotoComponent photoId={data?.findJournalEntry.photos.find(photo=>photo.id === parseInt(t))?.id}
              photoName={data?.findJournalEntry.photos.find(photo=>photo.id === parseInt(t))?.name}
              photoCaption={data?.findJournalEntry.photos.find(photo=>photo.id === parseInt(t))?.caption}
              photoLocation={data?.findJournalEntry.photos.find(photo=>photo.id === parseInt(t))?.location}
              photoCountry={data?.findJournalEntry.photos.find(photo=>photo.id === parseInt(t))?.country}
              photoDate={data?.findJournalEntry.photos.find(photo=>photo.id === parseInt(t))?.date}
              /> 
            : 
              <span>{t}</span>),
            <br/>,<br/>
              ]
            })
            }
          </div>
        <Wrapper apiKey={SiteConfig.GOOGLE_MAPS_API_KEY} render={render}>
        <Map
          mapTypeId={'terrain'}
          center={data && data.findJournalEntry.mapCenter}
          zoom={data && data.findJournalEntry.mapZoomLevel}
          style={{ width: "100%", height: "600px" }}
        >
        {data && data.findJournalEntry.rides.map(ride =>
          ride.paths.map(path => 
            <Polyline
              strokeColor={path.pathType === "ride" ? "#656398" : "#BBBBBB"}
              strokeOpacity={.7}
              strokeWeight={6}
              path={path.coordinates}
               />
          )
         )
        }      
       </Map>
      </Wrapper>
      </div>
      }
    </div>
  );
};

export default JournalEntryComponent;
