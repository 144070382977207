import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Image, Nav, Navbar, Stack } from 'react-bootstrap';
import {NavLink} from 'react-router-dom'
import { Route, Routes } from 'react-router-dom';
import About from './About';
import Background from './Background';
import Home from './Home';
import JournalEntryComponent from './JournalEntryComponent';
import JournalListComponent from './JournalListComponent';
import Photos from './Photos';
import { SiteConfig } from './SiteConfig';
import {LinkContainer} from 'react-router-bootstrap'

function App() {

  
return (
  
    <div style={{ display: "flex", height: "100%" }}>
      <Stack>
        <div className="container" style={{paddingLeft: "6px", paddingRight: "6px"}}>
        <div className={"wrapper"} >
          <div className={"banner-image"}>
            <Image width={133} height={100} src={`${SiteConfig.BASE_URL}/img/DSC01599.JPG`}></Image>
          </div>
          <div className={"banner-title"}><h1><b>The Road South</b></h1></div>
          <div className={"banner-subtitle"}><h5><i>A Pan-American Bicycle Journey</i></h5></div>
        </div>
        </div>
        <div className="container" style={{padding: "6px"}}>
      <Navbar style={{backgroundColor: "#656398", width: "100%"}} variant={"dark"}>
        <Container>
          <Nav>
            <LinkContainer to={"/"}>
              <Nav.Link >Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/background"}>
              <Nav.Link >Background</Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/journal_entries"}>
              <Nav.Link>Journal</Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/photos"}>
              <Nav.Link>Photos</Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/about"}>
              <Nav.Link>About</Nav.Link>
            </LinkContainer>            
            </Nav>
        </Container>
      </Navbar>
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/background" element={<Background/>}/>
        <Route path="/journal_entries" element={<JournalListComponent />}/>
        <Route path="/journal/:journalId" element={<JournalEntryComponent/>} />
        <Route path="/photos" element={<Photos/>}/>
        <Route path="/about" element={<About/>}/>
      </Routes>
      <div style={{textAlign:"center"}}>Copyright &copy; 2002-2022 Nick Lenzmeier.  All rights reserved.</div>
      </Stack>
    </div>
  );
};

export default App;
