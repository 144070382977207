import { gql, useQuery } from '@apollo/client';
import { Card } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { JournalEntry } from './models/JournalEntry';
import { SiteConfig } from './SiteConfig';

interface JournalListContainer {
  journalEntries: JournalEntry[]
}

const GET_JOURNAL_ENTRIES = gql`
  query Journal {
    journalEntries {
      id
      startLocation
      startDate
      endLocation
      image
      endDate
      summary
    }
  }
`;

function JournalListComponent() {

  const { loading, data } = useQuery<JournalListContainer>(
    GET_JOURNAL_ENTRIES
  );
    
  return (
    <div>
      { loading ? 
        <p></p>
        :
        <div className='container'>
        { data && data.journalEntries.map(journalEntry =>
        <Card style={{ width: '25rem' }}>
          <Card.Body>
            <Card.Img src={`${SiteConfig.BASE_URL}/img/${journalEntry.image}`}/>
            <Card.Title>{journalEntry.startLocation} to {journalEntry.endLocation}</Card.Title>
            <Card.Text><b>{journalEntry.endDate}.</b> {journalEntry.summary}</Card.Text>
            <Link
            style={{ display: "block", margin: "1rem 0" }}
            to={`/journal/${journalEntry.id}`}
            key={journalEntry.id}
          >
            Read More
          </Link>
          </Card.Body>
        </Card>
        )
        
        }
        </div>
      }
    </div>
  );
};

export default JournalListComponent;
