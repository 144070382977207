import React from 'react';

const Polyline: React.FC<google.maps.PolylineOptions> = (options) => {
    const [polyline, setPolyline] = React.useState<google.maps.Polyline>();
  
    React.useEffect(() => {
      if (!polyline) {
        setPolyline(new google.maps.Polyline());
      }
  
      return () => {
        if (polyline) {
          polyline.setMap(null);
        }
      };
    }, [polyline]);
  
    React.useEffect(() => {
      if (polyline) {
        polyline.setOptions(options);
      }
    }, [polyline, options]);
  
    return null;
  };
  
  export default Polyline;
  