import { gql, useQuery } from '@apollo/client';
import { Stack } from 'react-bootstrap';
import Trip from './models/Trip';
import { SiteConfig } from './SiteConfig';

interface TripContainer {
    findTrip: Trip;
    }
    

const GET_TRIP = gql`
    query Trip {
    findTrip(id: 1) {
        backgroundText,
        backgroundImage,
        backgroundImageCaption
    }
}`;

function Background(){

      
    const { loading, data } = useQuery<TripContainer>(
        GET_TRIP
      );
    
    return <div className="container">
        <div style={{maxWidth: "800px"}}>{data?.findTrip.backgroundText}</div>
        <Stack>
            <div style={{textAlign: "center"}}><b>{data?.findTrip.backgroundImageCaption}</b></div>
        <img style={{maxWidth: "800px", height:"auto"}} src={`${SiteConfig.BASE_URL}/img/${data?.findTrip.backgroundImage}`}/>
        </Stack>
      </div>
}

export default Background;